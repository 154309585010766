import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Subtitle,
  DivLogo,
  DivLogoSetion,
  DivRowOne,
  DivCursos,
  PCursos,
  Container,
  TitleCertification,
  Section,
  SkillsList,
  IMGLogo,
  SocialMedia,
  TextPartI,
  TextPartII,
  TextPartIII,
} from "./StyledSobre";
import CustomTooltip from "../Tooltip";

const api = process.env.REACT_APP_API;

const subtitleText = [
  "Desenvolvedor Full Stack",
  "React | Node.js | UX/UI Designer",
  "Criando Soluções Digitais",
  "Projetos que Transformam Ideias",
];

const subtitleAnimation = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1.5 } },
};

const skillsLI = [
  "Bootstrap 5",
  "Figma",
  "JSON",
  "Javascript",
  "MongoDB",
  "React",
  "SASS",
  "CSS3",
  "Git",
  "HTML5",
  "Illustrator",
  "Node.JS",
  "Photoshop",
  "React Bootstrap",
  "Tailwind CSS",
  "Vite",
  "VSC",
];

const Sobre = () => {
  const [currentSubtitle, setCurrentSubtitle] = useState(0);
  const [info, setInfo] = useState(null);
  const [skills, setSkills] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loadingSkills, setLoadingSkills] = useState(true);
  const [loadingCertifications, setLoadingCertifications] = useState(true);

  // Fetch Info
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`${api}/info`);
        setInfo(response.data);
      } catch {
        setInfo(null);
      } finally {
        setLoadingInfo(false);
      }
    };
    fetchInfo();
  }, []);

  // Fetch Skills
  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await axios.get(`${api}/skills`);
        setSkills(response.data);
      } catch {
        setSkills([]);
      } finally {
        setLoadingSkills(false);
      }
    };
    fetchSkills();
  }, []);

  // Fetch Certifications
  useEffect(() => {
    const fetchCertifications = async () => {
      try {
        const response = await axios.get(`${api}/certifications`);
        setCertifications(response.data);
      } catch {
        setCertifications([]);
      } finally {
        setLoadingCertifications(false);
      }
    };
    fetchCertifications();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSubtitle((prev) => (prev + 1) % subtitleText.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <h1>Sobre Mim</h1>
      <Subtitle
        variants={subtitleAnimation}
        initial="initial"
        animate="animate"
      >
        <AnimatePresence mode="wait">
          <motion.span
            key={currentSubtitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {subtitleText[currentSubtitle]}
          </motion.span>
        </AnimatePresence>
      </Subtitle>

      <DivRowOne>
        {/* Informações pessoais */}
        <Section>
          <h2>Informações Pessoais</h2>
          {loadingInfo ? (
            <Skeleton count={3} />
          ) : info ? (
            <>
              <TextPartI>
                Olá, eu sou o <strong>{info.name}</strong>
              </TextPartI>
              <TextPartII>
                Sou um <strong>{info.profession}</strong> apaixonado por criar
                soluções digitais que transformam ideias em realidade.
              </TextPartII>
              <TextPartIII>
                <strong>Estudante de {info.education}</strong>, estou sempre em
                busca de novas tecnologias e desafios para ampliar meus
                conhecimentos.
              </TextPartIII>
            </>
          ) : (
            <p>Erro ao carregar informações pessoais</p>
          )}
        </Section>

        {/* Skills */}
        <Section>
          <h3>Skills:</h3>
          {loadingSkills ? (
            <Skeleton count={skillsLI.length} height={30} />
          ) : skills.length > 0 ? (
            <SkillsList>
              {skills.map((skill, index) => (
                <CustomTooltip
                  key={index}
                  HoverToSee={<IMGLogo src={`${api}${skill}`} />}
                  textToHover={skillsLI[index]}
                >
                  <DivLogoSetion>
                    <DivLogo></DivLogo>
                  </DivLogoSetion>
                </CustomTooltip>
              ))}
            </SkillsList>
          ) : (
            <p>Erro ao carregar habilidades</p>
          )}
        </Section>
      </DivRowOne>

      {/* Certificações */}
      <Section>
        <h3>Certificações</h3>
        {loadingCertifications ? (
          <Skeleton count={5} height={30} />
        ) : certifications.length > 0 ? (
          certifications.map((formacao, index) => (
            <div key={index}>
              <TitleCertification>
                {formacao.nome} - Duração: {formacao.duracao}
              </TitleCertification>
              <DivCursos>
                {formacao.cursos.map((curso, cursoIndex) => (
                  <PCursos key={cursoIndex}>
                    {curso.nome}
                    <a
                      href={curso.link_certificado}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver Certificado
                    </a>
                  </PCursos>
                ))}
              </DivCursos>
            </div>
          ))
        ) : (
          <p>Erro ao carregar certificações</p>
        )}
      </Section>

      {/* Contato */}
      {info && (
        <Section>
          <h3>Contato</h3>
          <SocialMedia>
            <a
              href={info.socialMedia.github}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
            <a
              href={info.socialMedia.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href={info.socialMedia.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </SocialMedia>
        </Section>
      )}
    </Container>
  );
};

export default Sobre;
