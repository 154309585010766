// src/Components/Projects.jsx
import React from "react";
import styled from "styled-components";
import CardAnimado from "./CardAnimado/CardAnimado";

// const openInNewTab = (url) => {
//   window.open(url, "_blank", "noreferrer");
// };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden; /* Evitar que elementos filhos saiam da área visível */
`;
const TitleProject = styled.h1`
  color: #ddd;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  filter: blur(10px); /* Aplica o desfoque */
  z-index: -1; /* Coloca o overlay atrás do conteúdo */
`;

// const ProjectList = styled.ul`
//   list-style: none;
//   padding: 0;
//   width: 100%;
//   max-width: 600px;
// `;

// const PCursos = styled.p`
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
//   margin: 10px 0;
//   padding: 15px;
//   background: #34495e;
//   color: #ddd;
//   border: 1px solid #34495e;
//   border-radius: 8px;
//   font-size: 12px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: 100%;
//   font-weight: bold;
//   font-size: 1rem;
//   align-items: center;
//   text-align: center;
//   transition: 0.1s;

//   &:hover {
//     transition: 0.1s;
//     background-color: #2c3e50;
//     color: #ddd;
//   }

//   @media (min-width: 420px) {
//     text-align: start;
//     flex-direction: row;
//   }
// `;

const Projects = () => {
  // const handleClick = (link) => {
  //   openInNewTab(link);
  // };

  return (
    <Container>
      <BackgroundOverlay />
      <TitleProject>Meus Projetos</TitleProject>
      <CardAnimado />
    </Container>
  );
};

export default Projects;
