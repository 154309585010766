import React from 'react';
import "./CardAnimado.css";

const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

const CardAnimado = () => {

    const handleClick = (link) => {
        openInNewTab(link);
    };

    return (
        <div className='wrapper-card-animado'>
            <span className='stripe-cardanimado'></span>
            <div className='container-card-animado'>
                <input type="radio" name="slide-card-animado" id='c1' />
                <label htmlFor="c1" className='card-animado'>
                    <div className='row-animado'>
                        <div
                            className='icon-card-animado'
                            onClick={() => {
                                handleClick("https://ecommerce-iphones.vercel.app");
                            }}
                        >1
                        </div>
                        <div className='description-card-animado'>
                            <h4>Soline iPhones</h4>
                            <p>Um pequeno e-commerce integrado a um json com informações de diversos modelos de iphones.</p>
                        </div>
                    </div>
                </label>
                <input type="radio" name="slide-card-animado" id='c2' />
                <label htmlFor="c2" className='card-animado'>
                    <div className='row-animado'>
                        <div
                            className='icon-card-animado'
                            onClick={() => {
                                handleClick("https://thailyneethiago.vercel.app/");
                            }}>
                            2
                        </div>
                        <div className='description-card-animado'>
                            <h4>Site de casamento com confirmação de presença</h4>
                            <p>Um projeto pessoal muito especial, que pude implementar diversos conhecimentos e tecnologias.</p>
                        </div>
                    </div>
                </label>
                <input type="radio" name="slide-card-animado" id='c3' />
                <label htmlFor="c3" className='card-animado'>
                    <div className='row-animado'>
                        <div
                            className='icon-card-animado'
                            onClick={() => {
                                handleClick("#");
                            }}>3
                        </div>
                        <div className='description-card-animado'>
                            <h4>Projeto em construção - Béril engenharia</h4>
                            <p>Em breve disponibilizaremos o link do novo projeto realizado para a Béril Engenharia.</p>
                        </div>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default CardAnimado;
